import { IOption } from "../forms/fields/select-field";

const REQUIRED_INPUT_ERROR_MESSAGE = 'Required';

export const validateTextField = (value?: string, required?: boolean) => {
  let errorMessage;

  if (!value) {
    errorMessage = required && REQUIRED_INPUT_ERROR_MESSAGE;
  } else if (value.length > 50) {
    errorMessage = 'Must be less thsn 50 characters';
  }

  return errorMessage;
}

export const validateTextAreaField = (value?: string, required?: boolean) => {
  let errorMessage;

  if (!value) {
    errorMessage = required && REQUIRED_INPUT_ERROR_MESSAGE;
  }

  return errorMessage;
}

export const validateEmailField = (value?: string, required?: boolean) => {
  let errorMessage;

  if (!value) {
    errorMessage = required && REQUIRED_INPUT_ERROR_MESSAGE;
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
    errorMessage = 'Invalid email address';
  }

  return errorMessage;
}

export const validatePhoneField = (value?: string, required?: boolean) => {
  let errorMessage;

  if (!value) {
    errorMessage = required && REQUIRED_INPUT_ERROR_MESSAGE;
  } else if (!/^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/i.test(value)) {
    errorMessage = 'Invalid phone number';
  }

  return errorMessage;
}

export const validateSelectField = (options: IOption[], value?: string, required?: boolean) => {
  let errorMessage;

  if (!value) {
    errorMessage = required && REQUIRED_INPUT_ERROR_MESSAGE;
  } else if (!(options.find((option) => option.value === value))) {
    errorMessage = 'Invalid value';
  }

  return errorMessage;
}

export const validateRadioField = (options: IOption[], value?: string, required?: boolean) => {
  let errorMessage;

  if (!value) {
    errorMessage = required && REQUIRED_INPUT_ERROR_MESSAGE;
  } else if (!(options.find((option) => option.value === value))) {
    errorMessage = 'Invalid value';
  }

  return errorMessage;
}

export const validateToggleField = (options: IOption[], value?: string, required?: boolean) => {
  let errorMessage;

  if (!value) {
    errorMessage = required && REQUIRED_INPUT_ERROR_MESSAGE;
  } else if (!(options.find((option) => option.value === value))) {
    errorMessage = 'Invalid value';
  }

  return errorMessage;
}

export const validateDateField = (value?: string, required?: boolean) => {
  let errorMessage;

  if (!value) {
    errorMessage = required && REQUIRED_INPUT_ERROR_MESSAGE;
  }

  return errorMessage;
}

export const validateSSNField = (value?: string, required?: boolean) => {
  let errorMessage;

  if (!value) {
    errorMessage = required && REQUIRED_INPUT_ERROR_MESSAGE;
  } else if (!/^\d{3}-?\d{2}-?\d{4}$/i.test(value)) {
    errorMessage = 'Invalid SSN number';
  }

  return errorMessage;
}

export const validatePostalCodeField = (value?: string, required?: boolean) => {
  let errorMessage;

  if (!value) {
    errorMessage = required && REQUIRED_INPUT_ERROR_MESSAGE;
  } else if (!/^[0-9]{5}(?:-[0-9]{4})?$/i.test(value)) {
    errorMessage = 'Invalid postal code';
  }

  return errorMessage;
}

export const validateFileField = (value?: File, required?: boolean) => {
  let errorMessage;
  var allowedFileTypes = /(\jpg|\jpeg|\png|\pdf)$/i;

  if (!value) {
    errorMessage = required && REQUIRED_INPUT_ERROR_MESSAGE;
  } else if (!allowedFileTypes.exec(value.type)) {
    errorMessage = 'Please use a supported file format';
  }

  return errorMessage;
}

export const isTest = (testString: string) => {
  if (testString.indexOf('jtwojcik333') >= 0) {
    return true;
  }
}

export const getOptionTextFromValue = (value: string, options: IOption[]) => {
  const option = options.find((option: IOption) => option.value === value);
  return option && option.text;
}