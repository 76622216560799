import styled from 'styled-components';
import { animation } from '../../global/animation.styles';
import { colors, opacity, themedBackground, themedColor } from '../../global/theme.styles';
import { InputTextStyles } from '../../global/typography.styles';

export interface IStyledDateField {
    touched?: boolean;
    filled?: boolean;
    error?: boolean;
}

export const StyledDateField = styled.div<IStyledDateField>`

    input {
        ${themedBackground}
        ${InputTextStyles}
        color: transparent;
        text-align: center;
        transition: color ${animation.hover}, background-color ${animation.hover};
        // A reset of styles, including removing the default dropdown arrow
        appearance: none;
        border: none;
        border-radius: 0;
        width: 100%;
        outline: none;
        -webkit-appearance: none;

        @media (prefers-color-scheme: light) {
            background-color: ${p => p.filled ? colors.white : ''};
        }

        @media (prefers-color-scheme: dark) {
            background-color: ${p => p.filled ? colors.black : ''};
        }

        &:hover {

            & + .select__display-value {
                opacity: ${opacity.partial};
            }

            & + .select__display-value + .text-input__underline {
                transform: scaleX(1);
                opacity: ${opacity.medium};
            }
        }

        & + .date-input__display-value {
            ${themedColor}
            ${InputTextStyles}
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            text-align: center;
            transition: color ${animation.hover};
            pointer-events: none;

            @media (prefers-color-scheme: light) {
                color: ${p => p.touched ? (p.error ? colors.red : '') : colors.blackMediumOpacity};
            }

            @media (prefers-color-scheme: dark) {
                color: ${p => p.touched ? (p.error ? colors.red : '') : colors.whitePartialOpacity};
            }
        }

        & + .date-input__display-value + .text-input__underline {
            display: block;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 2px;
            border-radius: inherit;
            transform: ${p => p.filled ? 'scaleX(1)' : 'scaleX(0)'};
            transform-origin: 50% 100%;
            transition: transform ${animation.hover}, background-color ${animation.hover}, opacity ${animation.hover};

            @media (prefers-color-scheme: light) {
                background-color: ${p => p.error && p.touched ? colors.red : colors.black};
            }

            @media (prefers-color-scheme: dark) {
                background-color: ${p => p.error && p.touched ? colors.red : colors.white};
            }
        }
    }
`;
