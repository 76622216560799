import styled from 'styled-components';
import { animation } from '../../global/animation.styles';
import { media } from '../../global/media.styles';
import { colors, opacity, themedBackground, themedColor, themedFill } from '../../global/theme.styles';
import { InputTextStyles } from '../../global/typography.styles';

export interface IStyledSelectField {
    touched?: boolean;
    filled?: boolean;
    error?: boolean;
}

export const StyledSelectField = styled.div<IStyledSelectField>`
    select {
        ${themedBackground}
        ${InputTextStyles}
        color: transparent;
        text-align: center;
        transition: color ${animation.hover}, background-color ${animation.hover};
        // A reset of styles, including removing the default dropdown arrow
        appearance: none;
        border: none;
        border-radius: 0;
        width: 100%;
        cursor: pointer;
        outline: none;

        @media (prefers-color-scheme: light) {
            background-color: ${p => p.filled ? colors.white : ''};
        }

        @media (prefers-color-scheme: dark) {
            background-color: ${p => p.filled ? colors.black : ''};
        }

        &::-ms-expand {
            display: none;
        }

        &:focus {
            ${themedBackground}

            & + .select__display-value {
                color: ${p => p.error && p.touched ? colors.red : ''};
            }

            & + .select__display-value + .text-input__underline {
                transform: scaleX(1);
                opacity: ${opacity.medium};

                @media (prefers-color-scheme: light) {
                    background-color: ${p => p.error && p.touched ? colors.red : colors.black};
                }

                @media (prefers-color-scheme: dark) {
                    background-color: ${p => p.error && p.touched ? colors.red : colors.white};
                }
            }

            &:hover {

                & + .select__display-value {
                    ${themedColor}
                }

                & + .select__display-value + .text-input__underline {
                    opacity: ${p => p.filled ? opacity.none : opacity.medium};
                }
            }
        }

        &:hover {

            & + .select__display-value {
                opacity: ${opacity.partial};
            }

            & + .select__display-value + .text-input__underline {
                transform: scaleX(1);
                opacity: ${opacity.medium};
            }
        }

        & + .select__display-value {
            ${themedColor}
            ${InputTextStyles}
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            text-align: center;
            transition: color ${animation.hover};
            pointer-events: none;

            @media (prefers-color-scheme: light) {
                color: ${p => p.touched ? (p.error ? colors.red : '') : colors.blackMediumOpacity};
            }

            @media (prefers-color-scheme: dark) {
                color: ${p => p.touched ? (p.error ? colors.red : '') : colors.whitePartialOpacity};
            }
        }

        & + .select__display-value + .text-input__underline {
            display: block;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 2px;
            border-radius: inherit;
            transform: ${p => p.filled ? 'scaleX(1)' : 'scaleX(0)'};
            transform-origin: 50% 100%;
            transition: transform ${animation.hover}, background-color ${animation.hover}, opacity ${animation.hover};

            @media (prefers-color-scheme: light) {
                background-color: ${p => p.error && p.touched ? colors.red : colors.black};
            }

            @media (prefers-color-scheme: dark) {
                background-color: ${p => p.error && p.touched ? colors.red : colors.white};
            }
        }
    }

    .select-value {
        &-exit {
            transition: opacity ${animation.inView};
            opacity: ${opacity.none};
            overflow-x: hidden;

            &-active {
                opacity: ${opacity.full};
                max-width: 100vw;
                overflow-x: hidden;
            }
        }

        &-enter {
            opacity: ${opacity.full};
            max-width: 100vw;
            transition: opacity ${animation.inView};

            &-active {
                opacity: ${opacity.none};
                overflow-x: hidden;
            }
        }
    }

    .arrow-icon {
        position: absolute;
        top: 15px;
        right: 5px;
        height: 25px;
        width: 25px;
        transform: translate3d(-50%, -50%, 0) rotate(90deg);
        transform-origin: center;
        opacity: ${opacity.none};
        transition: opacity ${animation.hover} transform ${animation.hover};
        pointer-events: none;

        .arrow-icon__shape {
            ${themedFill}
            /* stroke: ${colors.white}; */
        }
    }
`;
