import React from "react";
import { Field, useField } from "formik";
import { IBaseFieldProps } from "./base-field";
import { FieldWrapper } from "./field-wrapper";
import { getOptionTextFromValue, validateSelectField } from "../../helpers/input";
import { StyledSelectField } from "./select-field.styles";
import { ThemeProvider } from "styled-components";
import { siteTheme } from "../../global/theme.styles";
import ArrowIcon from '../../svg/arrow.svg';
import { CSSTransition, SwitchTransition } from "react-transition-group";

export interface IOption {
    value: string;
    text: string;
}

export interface ISelectFieldProps extends IBaseFieldProps {
    selectOptions: IOption[];
    default?: string;
}

export const SelectField:React.FC<ISelectFieldProps> = ({ label, selectOptions, ...props }) => {
    // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
    // which we can spread on <input> and also replace ErrorMessage entirely.
    const selectFieldValidator = React.useCallback((value: string) => {
      return validateSelectField(selectOptions, value, props.required);
    }, [props.required]);
  
    const [field, meta] = useField({ name: props.name, validate: selectFieldValidator});

    return (
    <ThemeProvider theme={siteTheme.secondaryComponentTheme}>
    <StyledSelectField touched={meta.touched} filled={!!meta.value} error={!!meta.error}>
        <FieldWrapper 
            {...props}
            label={label}
            touched={meta.touched}
            filled={!!meta.value}
            error={meta.error}
        >
            <Field {...props}
            as="select" 
            className="select-input" 
            validate={selectFieldValidator} 
            >
                <option value=''>Select: {label}</option>
                {selectOptions.map((option: IOption, index: number) => {
                    return <option value={option.value} key={index}>{option.text}</option> 
                })}
            </Field>
            <div className="select__display-value">
                <SwitchTransition>
                    <CSSTransition key={meta.value}
                        classNames={'select-value'}
                        addEndListener={(node, done) => {
                            node.addEventListener("transitionend", done, false);
                        }}
                    >
                        <span>{getOptionTextFromValue(meta.value || meta.initialValue, selectOptions) || label}</span>
                    </CSSTransition>
                </SwitchTransition>
                <ArrowIcon />
            </div>
        </FieldWrapper>
    </StyledSelectField>
    </ThemeProvider>
    );
  };