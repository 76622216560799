import React from "react";
import { useModalContext } from "../global/contexts/modal.context";
import { StyledVideoModal } from "./video.styles";

export const VideoModal: React.FC = props => {
    const id = 'video';
    const { modalProps } = useModalContext();
    const videoType = modalProps && modalProps?.hashProps && modalProps.hashProps[0];
    const videoId = modalProps && modalProps?.hashProps && modalProps?.hashProps[1];

    return (
        <StyledVideoModal>
            {videoType == 'youtube' && videoId && 
                <div className='video-modal__video-container'>
                    <iframe src={`//www.youtube.com/embed/${videoId}`} frameBorder="0" allowFullScreen></iframe>
                </div>
            }
            {videoType == 'internal' && videoId && 
                <div className='video-modal__video-container'>
                </div>
            }
        </StyledVideoModal>
    );
}
