import React from "react";
import { IBaseFieldProps } from "./base-field";
import { validateSSNField } from "../../helpers/input";
import { TextField } from "./text-field";

export interface ISSNFieldProps extends IBaseFieldProps {
}

export const SSNField:React.FC<ISSNFieldProps> = (props) => {
  const ssnFieldValidator = React.useCallback((value: string) => {
    return validateSSNField(value, props.required);
  }, [props.required]);

  return (
    <TextField {...props} validator={ssnFieldValidator} />
  );
};