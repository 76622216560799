/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
import './src/global/webfonts.css';

// You can delete this file if you're not using it
export const shouldUpdateScroll = props => {
    return false;
};

