import { Formik } from "formik";
import React from "react";
import { trackEvent } from "../helpers/analytics";
import { IApplyFormState } from "./apply";
import { IContactFormState, formName as contactFormName } from "./contact";
import { useFormContext } from "./form.context";
import {StyledForm} from './form.styles';

export type FormStates = IContactFormState | IApplyFormState;

export interface IFormProps {
    formName: string;
    formText?: string;
    successMessage?: string;
    initialValues: IContactFormState | IApplyFormState;
}

const FormWrapper:React.FC<IFormProps> = props => {
  const { formState, updateFormState } = useFormContext();

  const submitForm = React.useCallback(async(values: typeof props.initialValues) => {
    trackEvent('submit_lead', {
      event_category: 'leads',
      event_label: `Submit ${props.formName} Form`,
      value: `submit_${props.formName}`
    });
    updateFormState && updateFormState('Submitting');
    let bodyString = JSON.stringify(values);

    // if (props.formName === 'apply') {
    //   let applyFormResume = values as Pick<IApplyFormState, 'resume'>;

    //   const resumeString = applyFormResume?.resume ? await readFileToString(applyFormResume.resume) : '';

    //   const applyFormData = {...values, resume: resumeString} as IApplyFormRequestData;

    //   bodyString = JSON.stringify(applyFormData);
    // }

    // console.log(bodyString);


    await fetch(`/api/${props.formName}`, {
      method: "POST",
      headers: {
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
      },
      body: bodyString,
    }).then((res) => {
      if (res.status === 200) {
        updateFormState && updateFormState('Success');
        trackEvent('submit_lead_success', {
          event_category: 'leads',
          event_label: `Successful ${props.formName} Form Submission`,
          value: `submit_${props.formName}_success`
        });
      } else {
        updateFormState && updateFormState('Failed');
        trackEvent('submit_lead_failure', {
          event_category: 'leads',
          event_label: `Failed ${props.formName} Form Submission`,
          value: `submit_${props.formName}_failure`
        });
      }

    }).catch((error) => {
      updateFormState && updateFormState('Failed');
      trackEvent('submit_lead_failure', {
        event_category: 'leads',
        event_label: `Failed ${props.formName} Form Submission`,
        value: `submit_${props.formName}_failure`
      });
    });

  }, [updateFormState]);

  return (
    <StyledForm isSuccess={formState === 'Success'}>
      <div className="form__content">
        <h3>{props.formName}</h3>
        <div className="form-text">{props.formText}</div>
        <Formik initialValues={props.initialValues}
          onSubmit={submitForm}
        >
          {props.children}
        </Formik>
      </div>
        <div className="form__success-message">
          {props.successMessage}
        </div>
    </StyledForm>
  )
}

export default FormWrapper;
