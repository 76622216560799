import { ButtonTextStyles } from "./typography.styles";
import { colors, layer, opacity, themedFill } from './theme.styles';
import { css } from "styled-components";
import { animation } from './animation.styles';
import { media } from "./media.styles";
import { CarouselButtonDirection } from "../parts/carousel/navigation-button";

export interface IButtonStylesProps {
    secondary?: boolean;
}

export const ButtonStyles = css<IButtonStylesProps>`
    ${ButtonTextStyles}
    position: relative;
    width: 100%;
    max-width: 500px;
    margin: 10px 0;
    padding: 10px 10px;
    justify-self: ${p => p.secondary ? 'start' : 'end'};
    color: ${p => p.secondary ? colors.brandBlue : colors.white};
    transition: color ${animation.hover};
    overflow: hidden;
    cursor: pointer;

    @media ${media.tabletLandscape} {
        padding: 10px 30px;
    }

    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: 4px solid transparent;
        background-color: ${p => p.secondary ? colors.lightGray : colors.brandBlue};
        border-radius: inherit;
        transform: translate3d(0, 0, 0);
        transform-origin: 50% 100%;
        transition: background-color ${animation.hover};
        z-index: ${layer.buttonBackground};
    }

    &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: calc(100% - 8px);
        height: calc(100% - 8px);
        margin: 4px;
        background-color: ${p => p.secondary ? colors.brandBlue : colors.white};
        border-radius: inherit;
        transform: translate3d(0, calc(100% + 4px), 0);
        transform-origin: 50% 100%;
        transition: transform ${animation.hover};
        z-index: ${layer.buttonBackgroundOverlay};
    }

    &:hover {
        color: ${p => p.secondary ? colors.white : colors.brandBlue};

        &:before {
            background-color: ${p => p.secondary ? colors.lightGray : colors.brandBlueHover};
        }

        &:after {
            transform: translate3d(0, 0, 0);
        }
    }
`;

export interface INavigationButtonProps {
    direction?: CarouselButtonDirection;
    disabled?: boolean;
}

export const BaseNavigationButtons = css<INavigationButtonProps>`
    ${ButtonTextStyles}
    height: 80px;
    padding: 10px 10px;
    font-size: 0;
    text-align: right;
    z-index: ${layer.modalNavigationButton};
    cursor: ${p =>  p.disabled ? 'not-allowed' : 'pointer'};
    overflow: hidden;

    @media ${media.tablet} {
        font-size: 0;
    }

    @media ${media.tabletLandscape} {
        font-size: 0;
    }

    &:hover {

        .arrow-icon {
            opacity: ${p =>  p.disabled ? opacity.half : opacity.partial};
        }
    }

    .arrow-icon {
        position: absolute;
        top: 50%;
        height: 50px;
        transform: translate3d(-50%, -50%, 0) ${p =>  p.direction === 'next' ? '' : 'rotate(180deg)'};
        transform-origin: center;
        opacity: ${p =>  p.disabled ? opacity.half : opacity.none};
        transition: opacity ${animation.hover};

        &__container {
            height: 100%;
            width: 100%;
            position: relative;
        }
    }
`;

export const ModalNavigationButton = css<INavigationButtonProps>`
    ${BaseNavigationButtons}
    display: block;
    position: fixed;
    bottom: 0;
    ${p =>  p.direction === 'next' ? 'right: 0' : 'left: 0'};
    width: 50%;
    backdrop-filter: blur(15px);

    @media ${media.tablet} {
        top: 50%;
        bottom: auto;
        height: 150px;
        width: 50px;
        border-top-left-radius: ${p =>  p.direction === 'next' ? '5px' : ''};
        border-bottom-left-radius: ${p =>  p.direction === 'next' ? '5px' : ''};
        border-top-right-radius: ${p =>  p.direction === 'next' ? '' : '5px'};
        border-bottom-right-radius: ${p =>  p.direction === 'next' ? '' : '5px'};
        transform: translate3d(0, -50%, 0);
    }

    &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: ${colors.white};
        opacity: ${p =>  p.disabled ? opacity.most : opacity.high};
        border-radius: inherit;
        transition: opacity ${animation.hover};
        z-index: ${layer.buttonBackground};
    }

    &:hover {

        &:after {
            opacity: ${p =>  p.disabled ? opacity.most : opacity.partial};
        }
    }
`;

export const InPageNavigationButton = css<INavigationButtonProps>`
    ${BaseNavigationButtons}
    display: inline-block;
    position: relative;
    width: 80px;

    @media ${media.tablet} {
        width: 80px;
    }

    .arrow-icon {
        ${themedFill}
    }

    &:hover {
        .arrow-icon {
            opacity: ${p =>  p.disabled ? opacity.half : opacity.partial};
            transition: opacity ${animation.hover};

            &__container {
                height: 100%;
                width: 100%;
                position: relative;
            }
        }
    }
`;

export interface IImageScrimStyleProps {
    hidden?: boolean;
}

export const ImageScrimStyle = (props: IImageScrimStyleProps) => {
    return css`
        position: relative;

        & .gatsby-image-wrapper {

            &::after {
                content: '';
                display: block;
                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
                width: 100%;
                background: ${colors.black};
                opacity: ${props.hidden ? opacity.full : opacity.most};
                transition: opacity ${animation.hover};
            }
        }
    `
};

export const ImageWithTextScrimStyle = (props: IImageScrimStyleProps) => {
    return css`
        position: relative;

        & .gatsby-image-wrapper {

            &::after {
                content: '';
                display: block;
                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
                width: 100%;
                background: ${colors.black};
                opacity: ${opacity.half};
                transition: opacity ${animation.hover};
            }
        }
    `
};

export interface IImageScrimHoverStyle {
    lighten?: boolean;
}

export const ImageScrimHoverStyle = (props: IImageScrimHoverStyle) => {
    return css`
        .gatsby-image-wrapper {
            &::after {
                opacity: ${props.lighten ? opacity.full : opacity.most};
            }
        }
    `;
};

export const ImageWithTextScrimHoverStyle = (props: IImageScrimHoverStyle) => {
    return css`
        .gatsby-image-wrapper {
            &::after {
                opacity: ${opacity.high};
            }
        }
    `;
};

export const NoScrollBarStyle = css`
    scrollbar-color: transparent transparent; /* thumb and track color */
    scrollbar-width: 0px;

    &::-webkit-scrollbar {
        width: 0;
        height: 0;
    }

    &::-webkit-scrollbar-track {
        background: transparent;
    }

    &::-webkit-scrollbar-thumb {
        background: transparent;
        border: none;
    }
`;

export const fadeInView = css`
    opacity: ${opacity.full};

    &.in-view {
        opacity: ${opacity.full};

        &--visible {
            transition: opacity ${animation.inView};
            opacity: ${opacity.none};
        }
    }
`;

export const fadeUpAndInView = css`
    opacity: ${opacity.full};
    transform: translate3d(0, 25px, 0);

    &.in-view {
        opacity: ${opacity.full};
        transform: translate3d(0, 25px, 0);

        &--visible {
            transition: opacity ${animation.inView}, transform ${animation.inView};
            opacity: ${opacity.none};
            transform: translate3d(0, 0, 0);
        }
    }
`;

export const fadeFromSideAndInView = css`
    opacity: ${opacity.full};

    &.in-view {
        max-width: 100vw;
        opacity: ${opacity.full};
        transform: translate3d(-25px, 0, 0);
        overflow-x: hidden;

        &--visible {
            transition: opacity ${animation.inView}, transform ${animation.inViewSecondary};
            opacity: ${opacity.none};
            transform: translate3d(0, 0, 0);
        }
    }
`;
