import React from 'react';
import { useLocation, navigate } from '@reach/router';
import { hasWindow, hasDocument } from '../../helpers/window';
import { IModalProps, modals } from '../../modals/modal';
import { trackEvent } from '../../helpers/analytics';
import { scrollTop } from '../../helpers/positions';

export type IModalState = Partial<ReturnType<typeof useModalState>>;

export const ModalContext = React.createContext<IModalState | null>(
null
);

export const useModalState = () => {
    const $html = React.useRef<HTMLElement | null>(null);
    const location = useLocation();
    const [
        isModalOpen,
        setIsModalOpen,
    ] = React.useState(false);
    const [
        modalId,
        setModalId,
    ] = React.useState<string | undefined>(undefined);
    const [
        modalProps,
        setModalProps,
    ] = React.useState<IModalProps | undefined>(undefined);
    const [
        pagePosition,
        setPagePosition,
    ] = React.useState(0);

    React.useEffect(() => {
        if (hasDocument) {
            $html.current = document.documentElement;
        }
    }, []);

    const setModalState = React.useCallback(
        (modalId: string, modalProps?: IModalProps) => {
            setModalId(modalId);
            setModalProps(modalProps);
            trackEvent('open_modal', {
                event_category: 'modal',
                event_label: `Open ${modalId} Modal`,
                value: modalId
            });
        },
        [isModalOpen]
    );

    const setModalOpen = React.useCallback(
        (modalOpen: boolean) => {
            if (isModalOpen && !modalOpen) {
                setModalId(undefined);
                navigate(location.pathname);
            }

            setIsModalOpen(modalOpen);
        },
        [isModalOpen]
    );

    React.useEffect(() => {
        if ($html.current) {
            if (isModalOpen) {
                const scrollPosiiton = scrollTop();
                setPagePosition(scrollPosiiton);
                $html.current.style.top = `-${scrollPosiiton}px`;
                $html.current.classList.add('modal-open');
                hasWindow && window.scrollTo(0, 0);
            } else {
                $html.current.classList.remove('modal-open');
                $html.current.style.top = '';
                hasWindow && window.scrollTo(0, pagePosition);
            }
        }},
        [isModalOpen]
    );

    React.useEffect(() => {
        if (modalId === undefined) {
            setIsModalOpen(false);
        } else {
            setIsModalOpen(true);
        }
    },
    [modalId]
    );

    React.useEffect(() => {
        const hash = location.hash;
        const [modalName, ...hashProps] = hash.replace('#', '').split('/');

        if (hash && modals[modalName] !== undefined) {
            setModalState(modalName, {hash: hash, hashProps: hashProps});
        } else {
            setModalOpen(false);
        }
        },
        [location.hash]
    );

    React.useEffect(() => {
        // Proably don't really need
        // !!isModalOpen && setIsModalOpen(false);
        },
        [location.pathname]
    );

    return {
        isModalOpen,
        setModalOpen,
        modalId,
        modalProps,
        setModalState,
    };
};

export const useModalContext = () => {
    const modalContext = React.useContext(ModalContext);

    if (!modalContext) {
        throw new Error(
        'ModalMeta Context used outside of ModalMetaContext.Provider'
        );
    }

    return modalContext;
};

export const ModalContextProvider: React.FC = ({ children }) => {
    const modalState = useModalState();

    return (
        <ModalContext.Provider value={modalState}>
            {children}
        </ModalContext.Provider>
    );
};
  