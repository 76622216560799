import { Form } from "formik";
import React from "react"
import { CSSTransition, SwitchTransition } from "react-transition-group";
import { CareerFragmentFragment, Maybe } from "../../gatsby-graphql";
import { careersToInputValues } from "../helpers/careers";
import { useCareers } from "../hooks/careers.hook";
import { StyledButton } from "../parts/button.styles"
import FieldGroup from "./field-group";
import { DateField } from "./fields/date-field";
import { EmailField } from "./fields/email-field";
import { FileField } from "./fields/file-field";
import { PhoneField } from "./fields/phone-field";
import { PostalCodeField } from "./fields/postal-code-field";
import { SelectField } from "./fields/select-field";
import { SSNField } from "./fields/ssn-field";
import { stateValues } from "./fields/state-field";
import { TextAreaField } from "./fields/text-area-field";
import { TextField } from "./fields/text-field";
import { ToggleField, toggleFieldValues } from "./fields/toggle-field";
import FormWrapper from "./form";
import { getSubmitTextFromState, useFormContext } from "./form.context";

export interface IApplyFormState {
    fullName: string,
    email: string,
    phone: string,
    address: string,
    city: string,
    state: string,
    zipcode: string,
    iceName: string,
    icePhone: string,
    ssn: string,
    ableToWorkUS: string,
    ofAge: string,
    position: string,
    salaryRequirements: string,
    startDate: string,
    discovery: string,
    currentlyEmployed: string,
    resume?: File,
}

export interface IApplyFormProps {
    initialPosition?: string
}

export interface IApplyFormRequestData extends Omit<IApplyFormState, 'resume'> {
    // resume: string
}

const AppyForm:React.FC<IApplyFormProps> = props => {
    const formName = 'apply';
    const { formState } = useFormContext();
    const careers = useCareers();
    const initialValues: IApplyFormState = {
      fullName: "",
      email: "",
      phone: "",
      address: "",
      city: "",
      state: "",
      zipcode: "",
      iceName: "",
      icePhone: "",
      ssn: "",
      ableToWorkUS: "",
      ofAge: "",
      position: props.initialPosition || "",
      salaryRequirements: "",
      startDate: "",
      discovery: "",
      currentlyEmployed: "",
    }

    return (
        <FormWrapper 
            initialValues={initialValues} 
            formName={formName}
            successMessage={'Thank you for submitting your application! We will be in touch with you soon.'}
            // formText={'Safety First Ambulance is an Equal Opportunity Employer. We consider applications for all positions without regard to Race, Color, Religion, Creed, Gender, National Origin, Age, Sexual Orientation, Marital or Veteran Status or any other legally protected status.This Application is a first step in obtaining an employment at Safety First Ambulance. Completion of this application does not guarantee employment. As a part of our pre-employment process, we will complete a background check, driving record check, and reference check.Safety First Ambulance offers a drug-free work environment. As a part of the Fitness-for-Duty process, employees are subject to pre-employment drug screening, and random drug tests.'}
        >
            <Form>
                <SelectField
                    label="Position"
                    name="position"
                    placeholder="Position"
                    required={true}
                    selectOptions={careers?.nodes ? careersToInputValues(careers.nodes) : [{ value: "Any", text: "any"}]}
                />
                <TextField
                    label="Full Name"
                    name="fullName"
                    placeholder="Full Name"
                    required={true}
                />
                <EmailField
                    label="Email"
                    name="email"
                    placeholder="Email"
                    required={true}
                />
                <PhoneField
                    label="Phone"
                    name="phone"
                    placeholder="Phone Number"
                    required={true}
                />
                <TextField
                    label="Address"
                    name="address"
                    placeholder="Address"
                    required={true}
                />
                <TextField
                    label="City"
                    name="city"
                    placeholder="City"
                    required={true}
                />
                <SelectField
                    label="State"
                    name="state"
                    placeholder="State"
                    required={true}
                    selectOptions={stateValues}
                />
                <PostalCodeField
                    label="Zipcode"
                    name="zipcode"
                    placeholder="Zipcode"
                    required={true}
                />
                <TextField
                    label="ICE (In Case of Emergency) Contact Name"
                    name="iceName"
                    placeholder="ICE (In Case of Emergency) Contact Name"
                    required={true}
                />
                <PhoneField
                    label="ICE (In Case of Emergency) Contact Phone"
                    name="icePhone"
                    placeholder="ICE (In Case of Emergency) Contact Phone"
                    required={true}
                />
                <SSNField
                    label="SSN"
                    name="ssn"
                    placeholder="SSN"
                    required={true}
                />
                <ToggleField
                    label="Are you eligible to work in the United States?"
                    name="ableToWorkUS"
                    placeholder="Are you eligible to work in the United States?"
                    required={true}
                    toggleOptions={toggleFieldValues}
                />
                <ToggleField
                    label="Are you over 18 years of age?"
                    name="ofAge"
                    placeholder="Are you over 18 years of age?"
                    required={true}
                    toggleOptions={toggleFieldValues}
                />
                <TextField
                    label="What are you salary requirements?"
                    name="salaryRequirements"
                    placeholder="What are you salary requirements?"
                    required={true}
                />
                <DateField
                    label="When can you start?"
                    name="startDate"
                    placeholder="When can you start?"
                    required={true}
                />
                <TextAreaField
                    label="How did you learn of our organization?"
                    name="discovery"
                    placeholder="How did you learn of our organization?"
                    required={true}
                />
                <ToggleField
                    label="Are you currently employed?"
                    name="currentlyEmployed"
                    placeholder="Are you currently employed?"
                    required={true}
                    toggleOptions={toggleFieldValues}
                />
                {/* <FileField
                    label="Upload your resume"
                    name="resume"
                    placeholder="Upload your resume"
                    required={true}
                 /> */}
            <StyledButton type="submit" disabled={formState !== 'Initial'}>
                <SwitchTransition>
                    <CSSTransition key={formState}
                        classNames={'submit-value'}
                        addEndListener={(node, done) => {
                            node.addEventListener("transitionend", done, false);
                          }}
                    >
                        <div className="form-button__submit-value">{getSubmitTextFromState(formState || 'Initial')}</div>
                    </CSSTransition>
                </SwitchTransition>
            </StyledButton>
          </Form>
        </FormWrapper>
    )
}

export default AppyForm;
